import { convertMediaTrack, errorPlayerNotCreated, } from './utils';
export const playerSelectedAudioTrack = (player) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    return {
        selectedAudioTrack: {
            track: player.selectedAudioTrack ? convertMediaTrack(player.selectedAudioTrack) : undefined,
        },
    };
};
