import { Output } from '@zattoo/ts-taf/protobuf/action';
import { createPlayer, EventKey, PlaybackState, } from '@zattoo/playback-sdk-react-native';
import { PlayerOutput_PlaybackStateChanged_State } from '@zattoo/ts-taf/protobuf/player_output';
import { convertMediaTrack, playerOptions, convertMediaType, convertTimeshiftStatus, } from './utils';
const attachPlaybackStateChanged = (player, dispatchOutput) => {
    player.on(EventKey.PLAYBACK_STATE_CHANGED, (event) => {
        let state;
        switch (event.state) {
            case PlaybackState.PLAYING:
                state = PlayerOutput_PlaybackStateChanged_State.PLAYING;
                break;
            case PlaybackState.BUFFERING:
                state = PlayerOutput_PlaybackStateChanged_State.BUFFERING;
                break;
            case PlaybackState.PAUSED:
                state = PlayerOutput_PlaybackStateChanged_State.PAUSED;
                break;
            case PlaybackState.STOPPED:
                state = PlayerOutput_PlaybackStateChanged_State.STOPPED;
                break;
            default:
                console.log(`Unknown state ${event.state}`);
                return;
        }
        const message = Output.create({
            playerEvent: {
                playbackStateChanged: {
                    state,
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachPlayerStateChanged = (player, dispatchOutput) => {
    player.on(EventKey.PLAYER_STATE_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                playerStateChanged: {
                    canSeekForward: event.canSeekForward,
                    canSeekBackward: event.canSeekBackward,
                    canPause: event.canPause,
                    seekableRange: {
                        start: event.seekableRange?.start,
                        end: event.seekableRange?.end,
                    },
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachAvailableAudioTrackChanged = (player, dispatchOutput) => {
    player.on(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                availableAudioTracksChanged: {
                    tracks: event.tracks.map(convertMediaTrack) ?? [],
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachSelectedAudioTrackChanged = (player, dispatchOutput) => {
    player.on(EventKey.SELECTED_AUDIO_TRACK_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                selectedAudioTrackChanged: {
                    track: {
                        locale: event.targetTrack?.locale,
                        codec: event.targetTrack?.codec,
                        id: event.targetTrack?.id,
                    },
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachAvailableSubtitlesTrackChanged = (player, dispatchOutput) => {
    player.on(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                availableSubtitlesTrackChanged: {
                    tracks: event.tracks.map((track) => {
                        return {
                            id: track.id,
                            codec: track.codec,
                            locale: track.locale,
                        };
                    }) ?? [],
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachSelectedSubtitlesTrackChanged = (player, dispatchOutput) => {
    player.on(EventKey.SELECTED_SUBTITLES_TRACK_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                selectedSubtitlesTrackChanged: {
                    track: event.targetTrack ? {
                        locale: event.targetTrack.locale,
                        codec: event.targetTrack.codec,
                        id: event.targetTrack.id,
                    } : undefined,
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachWatchRequested = (player, dispatchOutput) => {
    player.on(EventKey.WATCH_REQUESTED, () => {
        const message = Output.create({
            playerEvent: {
                watchRequested: {},
            },
        });
        dispatchOutput(message);
    });
};
const attachWatchReceived = (player, dispatchOutput) => {
    player.on(EventKey.WATCH_RECEIVED, (event) => {
        const message = Output.create({
            playerEvent: {
                watchReceived: {
                    csid: event.data.csid,
                    registerTimeshift: convertTimeshiftStatus(event.data.register_timeshift),
                    unregisteredTimeshift: convertTimeshiftStatus(event.data.unregistered_timeshift),
                    youthProtectionPinRequiredAt: event.data.youth_protection_pin_required_at,
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachPlayerReady = (player, dispatchOutput) => {
    player.on(EventKey.PLAYER_READY, () => {
        const message = Output.create({
            playerEvent: {
                playerReady: {},
            },
        });
        dispatchOutput(message);
    });
};
const attachMediaChanged = (player, dispatchOutput) => {
    player.on(EventKey.MEDIA_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                mediaChanged: {
                    url: event.media.url,
                    licenseUrl: event.media.licenseUrl ?? undefined,
                    prePadding: event.media.prePadding,
                    postPadding: event.media.postPadding,
                    mediaType: convertMediaType(event.media.mediaType),
                    dynamic: event.media.dynamic,
                    timeshiftAvailability: convertTimeshiftStatus(event.media.timeshiftAvailability ?? undefined),
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachCurrentPositionChanged = (player, dispatchOutput) => {
    player.on(EventKey.CURRENT_POSITION_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                currentPositionChanged: {
                    position: event.position,
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachSeeked = (player, dispatchOutput) => {
    player.on(EventKey.SEEKED, (event) => {
        const message = Output.create({
            playerEvent: {
                seeked: {
                    position: event.position,
                    seekedTime: event.seekedTime,
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachPlayerError = (player, dispatchOutput) => {
    player.on(EventKey.PLAYER_ERROR, (event) => {
        const message = Output.create({
            playerEvent: {
                playerError: {
                    code: event.error.code,
                    message: event.error.message,
                },
            },
        });
        dispatchOutput(message);
    });
};
const attachQualityChanged = (player, dispatchOutput) => {
    player.on(EventKey.QUALITY_CHANGED, (event) => {
        const message = Output.create({
            playerEvent: {
                qualityChanged: {
                    bitrate: event.bitrate,
                },
            },
        });
        dispatchOutput(message);
    });
};
export const playerCreate = (parameters, dispatchOutput) => {
    const player = createPlayer(playerOptions(parameters));
    attachPlaybackStateChanged(player, dispatchOutput);
    attachPlayerStateChanged(player, dispatchOutput);
    attachAvailableAudioTrackChanged(player, dispatchOutput);
    attachSelectedAudioTrackChanged(player, dispatchOutput);
    attachAvailableSubtitlesTrackChanged(player, dispatchOutput);
    attachSelectedSubtitlesTrackChanged(player, dispatchOutput);
    attachWatchRequested(player, dispatchOutput);
    attachWatchReceived(player, dispatchOutput);
    attachPlayerReady(player, dispatchOutput);
    attachMediaChanged(player, dispatchOutput);
    attachCurrentPositionChanged(player, dispatchOutput);
    attachSeeked(player, dispatchOutput);
    attachPlayerError(player, dispatchOutput);
    attachQualityChanged(player, dispatchOutput);
    return player;
};
