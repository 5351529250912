import { convertMediaTrack, errorPlayerNotCreated, } from './utils';
export const playerSelectedSubtitlesTrack = (player) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    return {
        selectedSubtitlesTrack: {
            track: player.selectedSubtitlesTrack ? convertMediaTrack(player.selectedSubtitlesTrack) : undefined,
        },
    };
};
