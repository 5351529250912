import { getRecordingStream } from '@zattoo/zapi';
import { MediaType } from './enums';
import { getStreamingProtocol } from '../capability';
import { EventKey, PlaybackState, } from '../player/enums';
import { watchParamsFromCapability, watchParamsFromPlayOptions, watchParamsFromStreamType, } from './utils';
import { generatePsid } from '../player/utils';
import { AssetType } from '../telemetry/enums/asset-type';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
export const PlayerWithRecording = (Base) => {
    return class extends Base {
        watchResponse = null;
        get isRecording() {
            return this.mediaType === MediaType.RECORDING;
        }
        async playRecording(id, playOptions) {
            try {
                const psid = generatePsid();
                this.triggerTelemetryNewSessionEvent(psid, AssetType.RECORDING, String(id));
                const capability = this.capabilities.default;
                await this.stop();
                this.psid = psid;
                const streamType = getStreamingProtocol(playOptions, capability);
                const param = {
                    recording_id: id.toString(10),
                    ...watchParamsFromStreamType(streamType),
                    ...watchParamsFromPlayOptions(playOptions),
                    ...watchParamsFromCapability(capability),
                };
                this.triggerEvent({
                    type: EventKey.WATCH_REQUESTED,
                    psid,
                });
                this.watchResponse = await getRecordingStream(param);
                if (!this.watchResponse) {
                    throw new Error('No watch response');
                }
                this.triggerEvent({
                    type: EventKey.WATCH_RECEIVED,
                    data: this.watchResponse,
                    psid,
                });
                const adapterRequest = {
                    type: MediaType.RECORDING,
                    parameters: {
                        recordingId: id,
                    },
                    capability,
                    watchResponse: this.watchResponse,
                    playOptions,
                    psid,
                };
                this.triggerTelemetryEvent({
                    type: TelemetryEventKey.STREAM_LOADING,
                    psid,
                });
                const adapterMedia = await this.adapter.load(adapterRequest);
                return this.dispatchMedia(adapterRequest, adapterMedia);
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
        setPlayerState(newState) {
            if (!this.isRecording || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: this.currentState === PlaybackState.PLAYING || this.currentState === PlaybackState.BUFFERING,
                canSeekBackward: true,
                canSeekForward: this.watchResponse?.stream?.forward_seeking,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
    };
};
