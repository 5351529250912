import { EventKey } from './enums/event-key';
// List of all event types containing status properties
const eventKeysWithStatus = [
    EventKey.PLAYING,
    EventKey.STATUS,
];
export const isStatusEvent = (event) => {
    // @ts-expect-error runtime check
    return eventKeysWithStatus.includes(event.type);
};
