import { WithImaSdk } from './sdk';
import { ImaActions } from './actions';
export const WithIma = (Base) => {
    return class AdapterWithImaSdk extends WithImaSdk(Base) {
        actions = new ImaActions(this);
        isAdPlaying() {
            return this.actions.isAdPlaying();
        }
        load(request) {
            this.imaStop();
            this.actions.loadNewSession(request.type);
            return super.load(request);
        }
        setVolume(volume) {
            if (this.actions.isAdPlaying()) {
                return this.imaSetVolume(volume);
            }
            else {
                return super.setVolume(volume);
            }
        }
        play() {
            if (this.actions.isAdPlaying()) {
                return this.imaPlay();
            }
            else {
                return super.play();
            }
        }
        pause() {
            if (this.actions.isAdPlaying()) {
                return this.imaPause();
            }
            else {
                return super.pause();
            }
        }
        stop() {
            if (this.actions.isAdPlaying()) {
                this.imaStop();
                this.onContentResumeRequested?.();
            }
            return super.stop();
        }
    };
};
