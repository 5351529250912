import { PlayerOutput_MediaType, PlayerOutput_TimeshiftAvailability, } from '@zattoo/ts-taf/protobuf/player_output';
import { MediaType, } from '@zattoo/playback-sdk';
import { TimeshiftStatus } from '@zattoo/zapi/lib/enums';
export const errorPlayerNotCreated = new Error('Player not created');
export const playOptions = (options) => {
    return {
        preferredAudioLanguage: options?.preferredAudioTrack ?? undefined,
        preferredSubtitlesLanguage: options?.preferredSubtitlesTrack ?? undefined,
        drmRequired: options?.drmRequired ?? undefined,
        pin: options?.pin ?? undefined,
        startupPosition: options?.startupPosition ?? undefined,
        adsUrl: options?.adsUrl ?? undefined,
        fairPlayCertificateUrl: options?.fairPlayCertificateUrl,
    };
};
export const playerOptions = (options) => {
    return {
        host: options.host ?? '',
        appId: options.appId ?? 0,
        appVersion: options.appVersion ?? '0',
        publicId: options.publicId ?? 'taf',
        stepBackwardDuration: options.stepBackwardDuration ?? 10,
        stepForwardDuration: options.stepForwardDuration ?? 10,
        muted: options.muted,
    };
};
export const convertMediaTrack = (mediaTrack) => {
    return {
        id: mediaTrack.id,
        codec: mediaTrack.codec,
        locale: mediaTrack.locale,
    };
};
export const convertMediaType = (mediaType) => {
    switch (mediaType) {
        case MediaType.LIVE:
            return PlayerOutput_MediaType.LIVE;
        case MediaType.VOD:
            return PlayerOutput_MediaType.VOD;
        case MediaType.REPLAY:
            return PlayerOutput_MediaType.REPLAY;
        case MediaType.RECORDING:
            return PlayerOutput_MediaType.RECORDING;
        case MediaType.REGISTERED_TIMESHIFT:
            return PlayerOutput_MediaType.REGISTERED_TIMESHIFT;
        case MediaType.URL:
            return PlayerOutput_MediaType.URL;
        default:
            throw new Error(`Unknown media type ${mediaType}`);
    }
};
export const convertTimeshiftStatus = (sdkTimeshiftStatus) => {
    switch (sdkTimeshiftStatus) {
        case TimeshiftStatus.AVAILABLE:
            return PlayerOutput_TimeshiftAvailability.AVAILABLE;
        case TimeshiftStatus.ELIGIBLE:
            return PlayerOutput_TimeshiftAvailability.ELIGIBLE;
        case TimeshiftStatus.SUBSCRIBABLE:
            return PlayerOutput_TimeshiftAvailability.SUBSCRIBABLE;
        case TimeshiftStatus.UNAVAILABLE:
            return PlayerOutput_TimeshiftAvailability.UNAVAILABLE;
        default:
            return undefined;
    }
};
