import { errorPlayerNotCreated } from './utils';
export const playerSetAudioTrack = (player, parameters) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    const track = parameters.track;
    player.setAudioTrack({
        id: track.id,
        locale: track.locale,
        codec: track?.codec ?? undefined,
    });
};
