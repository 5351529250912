import { AdapterCallbacks } from './adapter-callbacks';
export class AdapterCallbacksDispatcher extends AdapterCallbacks {
    logger;
    selectedAudioTrack = null;
    audioTracks = [];
    selectedSubtitlesTrack = null;
    subtitlesTracks = [];
    duration = null;
    psid = null;
    constructor(logger) {
        super();
        this.logger = logger;
    }
    resetState() {
        this.selectedAudioTrack = null;
        this.selectedSubtitlesTrack = null;
        this.audioTracks = [];
        this.subtitlesTracks = [];
        this.duration = null;
    }
    dispatchPlayerReady = () => {
        if (this.onPlayerReady) {
            this.onPlayerReady(this.psid);
        }
    };
    dispatchSelectedAudioTrackChanged = (track) => {
        this.selectedAudioTrack = track;
        this.onSelectedAudioTrackChanged?.(this.selectedAudioTrack, this.psid);
    };
    dispatchPlaybackDurationChanged = (duration) => {
        this.onPlaybackDurationChanged?.(duration, this.psid);
    };
    dispatchAvailableAudioTracksChanged = (audioTracks) => {
        this.audioTracks = audioTracks;
        this.onAvailableAudioTracksChanged?.(this.audioTracks, this.psid);
    };
    dispatchSeeked = (position) => {
        this.onSeeked?.(position, this.psid);
    };
    dispatchSelectedSubtitlesTrackChanged = (targetTrack) => {
        this.selectedSubtitlesTrack = targetTrack;
        this.onSelectedSubtitlesTrack?.(targetTrack, this.psid);
    };
    dispatchAvailableSubtitleTracksChanged = (subtitleTracks) => {
        this.subtitlesTracks = subtitleTracks;
        this.onAvailableSubtitlesTracksChanged?.(subtitleTracks, this.psid);
    };
    dispatchPositionChange = (time) => {
        this.onPositionChanged?.(time, this.psid);
    };
    dispatchSeekableRange = (seekableRange) => {
        this.onSeekableRangeChanged?.(seekableRange, this.psid);
    };
    dispatchPlaybackStateChanged = (state) => {
        this.onPlaybackStateChanged?.(state, this.psid);
    };
    dispatchVideoBitrateChanged = (bitrate) => {
        this.onVideoBitrateChanged?.(bitrate, this.psid);
    };
    dispatchError = (error) => {
        this.onError?.(error, this.psid);
    };
    dispatchGeometryChanged = (size) => {
        this.onGeometryChanged?.(size);
    };
    dispatchBandwidthMeasured = (bandwidth) => {
        this.onBandwidthMeasured?.(bandwidth);
    };
}
