import { EventKey, PlaybackState, } from '../enums';
import { TelemetrySession } from '../../telemetry';
import { EventKey as TelemetryEventKey } from '../../telemetry/enums/event-key';
export const WithTelemetry = (Base) => {
    return class PlayerWithEvent extends Base {
        #telemetrySessions = [];
        createStatusProperties() {
            const bufferInfo = this.adapter.getBufferInformation();
            const bufferDuration = bufferInfo
                ? ((bufferInfo.videoForward ?? 0) +
                    (bufferInfo.videoBackward ?? 0) +
                    (bufferInfo.audioForward ?? 0) +
                    (bufferInfo.audioBackward ?? 0))
                : -1;
            const statusProperties = {
                buf_dur: Math.round(bufferDuration),
                buf_dur_forward: Math.round(this.adapter.getBufferInformation()?.videoForward ?? -1),
                cur_media_time: Math.round(this.currentPosition ?? 0),
                cur_bitrate: this._bitrate ?? -1,
                playback_window_start: Math.round(this.seekableRange?.start ?? 0),
                playback_window_end: Math.round(this.seekableRange?.end ?? 0),
                net_dl_speed_est: Math.round(this.bandwidth ?? 0),
                cpu_usage: this.adapter.getSystemStats()?.cpuUsage,
                mem_free: this.adapter.getSystemStats()?.memoryFree,
            };
            return statusProperties;
        }
        getTelemetrySession = (psid) => {
            let telemetrySession = this.#telemetrySessions.find((session) => session.psid === psid);
            if (!telemetrySession) {
                telemetrySession = new TelemetrySession(() => {
                    return this.createStatusProperties();
                }, {
                    psid,
                    appId: this._playerOptions.appId,
                    appVersion: this._playerOptions.appVersion,
                    publicId: this._playerOptions.publicId,
                });
                this.#telemetrySessions.push(telemetrySession);
            }
            return telemetrySession;
        };
        formatCapabilities = (capabilites) => {
            if (!capabilites) {
                return [];
            }
            return [
                ...capabilites.audio.map((codec) => `audio/${codec.name}`),
                ...capabilites.video.map((codec) => `video/${codec.name}`),
                ...capabilites.drm.map((drm) => (`drm/${drm.name};${drm.maxSecurityLevel ?? 'unknown'};${drm.version ?? 'unknown'}`)),
                ...capabilites.hdcp.map((hdcp) => (`hdcp/${hdcp.connectedLevel ?? 'unknown'};${hdcp.maxSupportedLevel ?? 'unknown'}`)),
            ];
        };
        triggerTelemetryNewSessionEvent(psid, assetType, assetId) {
            const telemetrySession = this.getTelemetrySession(psid);
            telemetrySession.appendEventPromise(async () => {
                const capabilites = await this.adapter.getCapabilities();
                // buffer config is only available after the player is ready
                await new Promise((resolve) => {
                    let timeout;
                    const handler = () => {
                        this.off(EventKey.PLAYER_READY, handler);
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        resolve();
                    };
                    timeout = setTimeout(handler, 1000);
                    this.on(EventKey.PLAYER_READY, handler);
                });
                const userAgent = (typeof window !== 'undefined' &&
                    window?.navigator?.userAgent) || 'unknown';
                return {
                    type: TelemetryEventKey.NEW_SESSION,
                    psid,
                    browser_name: userAgent,
                    player_name: this.adapter.playerName,
                    player_ver: this.adapter.playerVersion,
                    buf_player_conf_min_buf: this.adapter.getBufferInformation()?.miniumVideoBufferForward ?? -1,
                    asset_type: assetType,
                    asset_id: assetId,
                    player_size_w: this._geometry?.playerWidth ?? -1,
                    player_size_h: this._geometry?.playerHeight ?? -1,
                    screen_size_w: this._geometry?.screenWidth ?? -1,
                    screen_size_h: this._geometry?.screenHeight ?? -1,
                    capabilites: this.formatCapabilities(capabilites),
                };
            });
        }
        triggerTelemetryEvent(payload) {
            const telemetrySession = this.getTelemetrySession(payload.psid);
            telemetrySession.appendEvent(payload);
        }
        evaluateEvent = (event) => {
            switch (event.type) {
                case EventKey.WATCH_REQUESTED: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.WATCH_SENT,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.WATCH_RECEIVED: {
                    const telemetrySession = this.getTelemetrySession(event.psid);
                    if (!event.data.tracking?.temp_telemetry_enabled) {
                        telemetrySession.disableTelemetry();
                        break;
                    }
                    telemetrySession.setEndpoint(event.data.tracking?.temp_telemetry_url ?? null);
                    telemetrySession.setCsid(event.data.csid);
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.WATCH_RECEIVED,
                        url: event.data.stream.url,
                        license_url: event.data.stream.license_url,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.PLAYER_READY: {
                    const telemetrySession = this.getTelemetrySession(event.psid);
                    const endpoint = this._streamInformation?.watchResponse.tracking?.temp_telemetry_url;
                    if (
                    // we had no watch received event
                    // and the endpoint was passed externally
                    !telemetrySession.hasEndpoint() &&
                        endpoint) {
                        telemetrySession.setEndpoint(endpoint);
                    }
                    else if (
                    // disable telemetry also if the endpoint
                    // was not passed externally
                    !telemetrySession.hasEndpoint() &&
                        !endpoint) {
                        telemetrySession.disableTelemetry();
                    }
                    break;
                }
                case EventKey.PLAYBACK_STATE_CHANGED: {
                    switch (event.state) {
                        case PlaybackState.PLAYING: {
                            this.triggerTelemetryEvent({
                                type: TelemetryEventKey.PLAYING,
                                psid: event.psid,
                            });
                            // todo: Cleanup of old sessions should happen on
                            // PlaybackState.STOPPED.
                            // For that we need to pass the psid to the adapter.
                            const openSessions = this.#telemetrySessions.filter((session) => session.psid !== event.psid);
                            openSessions.forEach((session) => {
                                session.close();
                            });
                            this.#telemetrySessions = this.#telemetrySessions.filter((session) => session.psid === event.psid);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        };
        triggerFullEvent = (event) => {
            // consider to defer this off the main event loop
            this.evaluateEvent(event);
            return super.triggerFullEvent(event);
        };
        destroy() {
            this.#telemetrySessions.forEach((session) => {
                session.close();
            });
            this.#telemetrySessions = [];
            return super.destroy();
        }
    };
};
