// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v4.25.3
// source: zapi.proto
/* eslint-disable */
import _m0 from "protobufjs/minimal";
export const protobufPackage = "taf";
function createBaseZapi() {
    return {};
}
export const Zapi = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZapi();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Zapi.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseZapi();
        return message;
    },
};
function createBaseZapi_Create() {
    return { host: "" };
}
export const Zapi_Create = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.host !== "") {
            writer.uint32(10).string(message.host);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZapi_Create();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.host = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { host: isSet(object.host) ? globalThis.String(object.host) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.host !== "") {
            obj.host = message.host;
        }
        return obj;
    },
    create(base) {
        return Zapi_Create.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseZapi_Create();
        message.host = object.host ?? "";
        return message;
    },
};
function createBaseZapi_SessionHello() {
    return { appTid: "", appVersion: "", uuid: "", language: "" };
}
export const Zapi_SessionHello = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.appTid !== "") {
            writer.uint32(10).string(message.appTid);
        }
        if (message.appVersion !== "") {
            writer.uint32(18).string(message.appVersion);
        }
        if (message.uuid !== "") {
            writer.uint32(26).string(message.uuid);
        }
        if (message.language !== "") {
            writer.uint32(34).string(message.language);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZapi_SessionHello();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.appTid = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.appVersion = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.uuid = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.language = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            appTid: isSet(object.appTid) ? globalThis.String(object.appTid) : "",
            appVersion: isSet(object.appVersion) ? globalThis.String(object.appVersion) : "",
            uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
            language: isSet(object.language) ? globalThis.String(object.language) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.appTid !== "") {
            obj.appTid = message.appTid;
        }
        if (message.appVersion !== "") {
            obj.appVersion = message.appVersion;
        }
        if (message.uuid !== "") {
            obj.uuid = message.uuid;
        }
        if (message.language !== "") {
            obj.language = message.language;
        }
        return obj;
    },
    create(base) {
        return Zapi_SessionHello.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseZapi_SessionHello();
        message.appTid = object.appTid ?? "";
        message.appVersion = object.appVersion ?? "";
        message.uuid = object.uuid ?? "";
        message.language = object.language ?? "";
        return message;
    },
};
function createBaseZapi_SignIn() {
    return { email: "", password: "" };
}
export const Zapi_SignIn = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.email !== "") {
            writer.uint32(10).string(message.email);
        }
        if (message.password !== "") {
            writer.uint32(18).string(message.password);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseZapi_SignIn();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.email = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.password = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            email: isSet(object.email) ? globalThis.String(object.email) : "",
            password: isSet(object.password) ? globalThis.String(object.password) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.email !== "") {
            obj.email = message.email;
        }
        if (message.password !== "") {
            obj.password = message.password;
        }
        return obj;
    },
    create(base) {
        return Zapi_SignIn.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseZapi_SignIn();
        message.email = object.email ?? "";
        message.password = object.password ?? "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
