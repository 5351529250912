import { errorPlayerNotCreated } from './utils';
export const playerSetSubtitlesTrack = (player, parameters) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    const track = parameters.track;
    if (track) {
        player.setSubtitlesTrack({
            codec: track.codec,
            locale: track.locale,
            id: track.id,
        });
    }
    else {
        player.setSubtitlesTrack(null);
    }
};
