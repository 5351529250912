import { convertMediaTrack, errorPlayerNotCreated, } from './utils';
export const playerAvailableAudioTracks = (player) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    return {
        availableAudioTracks: {
            tracks: player.availableAudioTracks.map(convertMediaTrack),
        },
    };
};
