// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v4.25.3
// source: taf_action.proto
/* eslint-disable */
import _m0 from "protobufjs/minimal";
export const protobufPackage = "taf";
function createBaseTafAction() {
    return {};
}
export const TafAction = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction();
        return message;
    },
};
function createBaseTafAction_Sleep() {
    return { ms: 0 };
}
export const TafAction_Sleep = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ms !== 0) {
            writer.uint32(8).uint32(message.ms);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_Sleep();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.ms = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { ms: isSet(object.ms) ? globalThis.Number(object.ms) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.ms !== 0) {
            obj.ms = Math.round(message.ms);
        }
        return obj;
    },
    create(base) {
        return TafAction_Sleep.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_Sleep();
        message.ms = object.ms ?? 0;
        return message;
    },
};
function createBaseTafAction_SetupZapiProxy() {
    return { host: "" };
}
export const TafAction_SetupZapiProxy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.host !== "") {
            writer.uint32(10).string(message.host);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.host = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { host: isSet(object.host) ? globalThis.String(object.host) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.host !== "") {
            obj.host = message.host;
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiProxy();
        message.host = object.host ?? "";
        return message;
    },
};
function createBaseTafAction_StartSession() {
    return { name: "" };
}
export const TafAction_StartSession = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_StartSession();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        return obj;
    },
    create(base) {
        return TafAction_StartSession.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_StartSession();
        message.name = object.name ?? "";
        return message;
    },
};
function createBaseTafAction_CleanupSession() {
    return {};
}
export const TafAction_CleanupSession = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_CleanupSession();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction_CleanupSession.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction_CleanupSession();
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
