import { useRef, useSyncExternalStore, } from 'react';
import { TafAdapter, EventKey as TafAdapterEventKey, } from './taf-adapter';
export const useTafPlayerAdapter = (configuration) => {
    const tafAdapter = useRef(null);
    if (!tafAdapter.current) {
        tafAdapter.current = new TafAdapter(configuration);
    }
    const subscribeSession = (callback) => {
        tafAdapter.current?.addEventListener(TafAdapterEventKey.NewSession, callback);
        return () => {
            tafAdapter.current?.removeEventListener(TafAdapterEventKey.NewSession, callback);
        };
    };
    const snapshotSession = () => {
        if (!tafAdapter.current) {
            return null;
        }
        return tafAdapter.current.getSession();
    };
    const session = useSyncExternalStore(subscribeSession, snapshotSession);
    const subscribePlayer = (callback) => {
        tafAdapter.current?.addEventListener(TafAdapterEventKey.PlayerCreated, callback);
        tafAdapter.current?.addEventListener(TafAdapterEventKey.PlayerDestroyed, callback);
        return () => {
            tafAdapter.current?.removeEventListener(TafAdapterEventKey.PlayerCreated, callback);
            tafAdapter.current?.removeEventListener(TafAdapterEventKey.PlayerDestroyed, callback);
        };
    };
    const snapshotPlayer = () => {
        if (!tafAdapter.current) {
            return null;
        }
        return tafAdapter.current.getPlayer();
    };
    const player = useSyncExternalStore(subscribePlayer, snapshotPlayer);
    return {
        player,
        session,
    };
};
